<template>
  <base-layout>
    <ion-searchbar
        show-cancel-button="focus"
        @ionChange="filter(search)"
        :value="search"
        @ionInput="search = $event.target.value;"
    ></ion-searchbar>
    <ion-content class="has-header has-subheader">

      <div class="wcpt-list">
        <div v-for="exhibitor in filteredExhibitors" :key="exhibitor.id">
          <div
              class="web-person-divider"
              v-if="exhibitor.isLetter"
              style="width:100vw;padding-left: 20px"
          >
            <h2>{{ exhibitor.letter }}</h2>
          </div>
          <div v-if="!exhibitor.isLetter">
            <a
                @click="showDisclaimer(exhibitor)"
                class="wcpt-list-item item item-icon-right"
                style="text-align: center; height:130px;"
            >
              <img
                  v-if="exhibitor.logo"
                  :src="'https://fileserver-188ab.kxcdn.com/file/' +exhibitor.logo.split('/')[4]"
                  style="max-height: 70px; padding:10px 20px 0 20px"
              />
              <p>{{ exhibitor.short_name || exhibitor.name }}<br>
              <small v-for="location in exhibitor.locationList" :key="location.id"> <span v-if="location.name"> Visit our booth: {{location.name}} </span> </small></p>
             

            </a>
          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>
<script>
import {alertController, IonContent, IonSearchbar} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";

export default defineComponent({
  name: "Exhibitors",
  data() {
    return {
      exhibitors: [],
      search: "",
      filtered: []
    };
  },
  components: {
    IonContent,
    IonSearchbar
  },
  methods: {
    ...mapActions("exhibitors", ["getList"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    filter(term) {
      if (term.length > 0) {
        this.filtered = this.exhibitors.filter(exhibitor =>
            Object.keys(exhibitor).some(k =>
                exhibitor[k]
                    ? exhibitor[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
      } else {
        this.filtered = [];
      }
    },
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = 'A'.charCodeAt(0) - 100;
      let sortedList = [];

      function addLetter(code) {
        let letter = String.fromCharCode(code);
        sortedList.push({
          isLetter: true,
          letter: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector].toUpperCase().charCodeAt(0);
          let difference = itemCharCode - currentCharCode;
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });

      return sortedList;
    },
    async showDisclaimer(exhibitor) {
      if (exhibitor.disclaimer && exhibitor.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: exhibitor.name,
              message: '<strong>' + exhibitor.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(exhibitor.name);
                    if (exhibitor.disclaimer_link) {
                      window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
                    } else {
                      this.$router.push('/app/exhibitors/' + exhibitor.id);
                    }
                  },
                },
              ],
            });
        return alert.present();
      } else if (exhibitor.disclaimer_link && exhibitor.disclaimer_link.length > 10) {
        window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
      } else {
        this.$router.push('/app/exhibitors/' + exhibitor.id);
      }

    }
  },
  computed: {
    filteredExhibitors: function () {
      let returnList = this.filtered.length ? this.filtered : this.exhibitors;
      return this.groupList(returnList, 'name')
    }
  },
  async created() {
    let list = await this.getList();
    list = list.sort((a, b) => {
      if (a.name) {
        return a.name.localeCompare(b.name);
      }
    });
    this.exhibitors = this.groupList(list, 'name')
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        let list = await this.getList();
        list = list.sort((a, b) => {
          if (a.name) {
            return a.name.localeCompare(b.name);
          }
        });
        this.exhibitors = this.groupList(list, 'name')
      }
    },
  },
});
</script>
<style lang="scss">
a {
  text-decoration: none;
}
</style>